import ResponsiveMedia from '@components/ResponsiveMedia';
import AppButton from '@components/AppButton';
import { H1, Grid } from '#ui';
import { propsValidator } from '@lib/utils';

import type { ErrorHeroProps as Props } from './types';
import type { FC } from 'react';

const ErrorHero: FC<Props> = ({
  heading,
  responsiveMedia,
  ctasCollection,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  return (
    <Grid as="header" sx={{ justifyItems: 'center' }}>
      {!!responsiveMedia?.desktopAsset?.url && (
        <ResponsiveMedia
          sx={{
            inlineSize: 'min(100%, 44rem)',
            marginBlockStart: 5,
          }}
          lazy={false}
          {...responsiveMedia}
        />
      )}
      <H1
        sx={{
          inlineSize: 'min(100%, 41rem)',
          textAlign: 'center',
          marginBlockStart: [5, 6],
        }}
      >
        {heading}
      </H1>
      {!!ctasCollection?.items.length &&
        ctasCollection.items.map((cta, index) => {
          if (!cta.ctaCopy) return null;
          return (
            <AppButton
              {...cta}
              key={index}
              color="Violet"
              componentStyle={{
                marginBlockStart: 5,
                marginBlockEnd: 5,
                inlineSize: ['100%', 'fit-content'],
              }}
            >
              {cta.ctaCopy}
            </AppButton>
          );
        })}
    </Grid>
  );
};

export default ErrorHero;
